<template>
  <div>
    <div class="content-wrapper" style="border-bottom: 1px solid #0001">

      <!-- Header Log inicial  -->
      <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
          <div style="border-bottom: 1px solid #0001" class="row mb-2">
            <div class="col-sm-12 d-flex justify-content-between align-items-center">
              <h4 style="color: #0007" class="mt-3">
                <i class="fa fa-clipboard-list"></i>
                Logs
              </h4>
            </div>
          </div>
        </div>
      </section>
      <!-- Fim Header Log inicial -->

      <section class="content-header p-1 px-2" v-if="!showLogsModal">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <!-- Card Header -->
                <div class="card-header">
                  <h3 class="card-title">
                    Listagem de Logs
                  </h3>

                  <!--                  // TODO barra de pesquisa -->
                  <!--                  <div class="card-tools d-flex">-->
                  <!--                    <div class="input-group input-group-sm" style="width: 400px;position: relative !important">-->
                  <!--                      <input @keyup="getFilterAdmins()" v-model="searchAdmins"-->
                  <!--                             title="Pesquisar Administrador"-->
                  <!--                             type="text"-->
                  <!--                             name="search_admin"-->
                  <!--                             class="form-control float-right input-search-admin"-->
                  <!--                             placeholder="Pesquisar"-->
                  <!--                      />-->
                  <!--                      <i class="fas fa-search search-admin" title="Pesquisar Admnistrador"-->
                  <!--                         @click="getFilterAdmins()">-->
                  <!--                      </i>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
                <!-- Fim Card Header -->

                <div class="card-body table-responsive p-0" style="max-height: 700px;">
                  <table class="table table-hover table-head-fixed">
                    <thead>
                    <tr>
                      <th>API</th>
                      <th>Ação</th>
                      <th>Responsável</th>
                      <th>Dados</th>
                      <th>Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="log in logs" :key="log._id">
                      <td>{{ log.api }}</td>
                      <td>{{ log.action }}</td>
                      <td>{{ log.owner }}</td>
                      <td>{{ logDataFilter(log.data) }}</td>
                      <td style="width:200px">
                        <div @click="showLog(log)"
                             tag="button"
                             class="btn btn-outline-secondary mr-1 btn-sm"
                             title='Vizualizar'>
                          <i class="fa fa-eye"></i>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="text-center mt-3" v-if="!showLogsModal">
                    <nav>
                      <ul class="pagination justify-content-center">
                        <li class="page-item">
                          <button :class="page === 1 ? 'page-link disabled' : 'page-link'"
                                  :disabled="page === 1 ? true : false" :style=" page === 1 ? { cursor: 'not-allowed' } : {}"
                                  @click="previousPage()">Voltar
                          </button>
                        </li>
                        <li class="page-item">
                          <button v-if="page > 2" class="page-link disabled">...</button>
                        </li>
                        <li class="page-item" v-for="(thisPage, i) in pages" :key="i">
                          <button v-if="thisPage === page - 1 || thisPage === page + 1 || thisPage === page " :class="thisPage === page ? 'btn-primary form-control' : 'page-link'" @click="selectedPage(thisPage)">{{ thisPage }}</button>
                        </li>
                        <li class="page-item">
                          <button v-if="page < pages - 2" class="page-link disabled">...</button>
                        </li>
                        <li class="page-item">
                          <button :class="calcTotalPages() ? 'page-link disabled' : 'page-link'" :disabled="calcTotalPages()"
                                  :style="calcTotalPages() ? { cursor: 'not-allowed' } : {}" @click="nextPage()">Próximo
                          </button>
                        </li>
                      </ul>
                    </nav>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Fim Sessão Listar Logs -->
      
      <section class="content-header p-1 px-2" v-if="showLogsModal">
        <div class="d-flex justify-content-start pt-3 pl-3">
          <i @click="hideLog()" class="fas fa-arrow-left back-icon" style="border-radius: 8px;"></i>
        </div>
        <div class="column">
          <div class="d-flex flex-column col-12 mt-2">
            <div class="label d-flex justify-content-center">
              <label class="word-custom">API</label>
            </div>
            <div class="form-group d-flex justify-content-center">
              <input
                  type="text"
                  name="name-company"
                  class="form-control w-50 text-center"
                  placeholder="API"
                  v-model="showLogsApi"
                  disabled
              />
            </div>
          </div>

          <div class="d-flex flex-column col-12">
            <div class="label d-flex justify-content-center">
              <label class="word-custom">Ação</label>
            </div>
            <div class="form-group d-flex justify-content-center">
              <input
                  type="text"
                  name="corporate-name"
                  class="form-control w-50 text-center"
                  placeholder="Ação"
                  v-model="showLogsAction"
                  disabled
              />
            </div>
          </div>

          <div class="d-flex flex-column col-12 ">
            <div class="label d-flex justify-content-center">
              <label class="word-custom">Responsável</label>
            </div>
            <div class="form-group d-flex justify-content-center">
              <input
                  type="text"
                  name="cnpj"
                  class="form-control w-50 text-center"
                  placeholder="Responsável"
                  v-model="showLogsOwner"
                  disabled
              />
            </div>
          </div>

          <div class="d-flex flex-column col-12 ">
            <div class="label d-flex justify-content-center">
              <label class="word-custom">Dados</label>
            </div>
            <div class="form-group d-flex justify-content-center">
              <textarea
                  type="text"
                  name="cnpj"
                  style="height: 400px; !important;"
                  class="form-control w-50 text-center"
                  placeholder="Dados"
                  v-model="showLogsData"
                  disabled
              />
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</template>


<script>
import axios from 'axios'

export default {

  data() {
    return {
      logs: [],
      showLogsModal: false,
      showLogsApi: '',
      showLogsAction: '',
      showLogsOwner: '',
      showLogsData: {},
      page: 1,
      limit: 6,
      pages: [],
      totalPages: 0,
      totalLogs: 0,
    }
  },

  mounted() {
    this.getLogs();
  },

  methods: {

    calcTotalPages() {
      return !(this.page < this.totalPages)
    },

    selectedPage(newPage) {
      this.page = newPage;
      this.getLogs();
    },

    nextPage() {
      if (this.page < this.totalPages) {
        this.page = this.page + 1;
        this.getLogs();
      }
    },

    previousPage() {
      if (this.page > 0) {
        this.page = this.page - 1;
        this.getLogs();
      }
    },

    logDataFilter(data){
      if(!data)
        return ''
      return JSON.stringify(data).substring(0, 50) + "..."
    },

    showLog(log) {
      this.showLogsModal = true;
      this.showLogsApi = log.api
      this.showLogsAction = log.action
      this.showLogsOwner = log.owner
      this.showLogsData = JSON.stringify(log.data)
    },

    hideLog(log) {
      this.showLogsModal = false;
      this.showLogsApi = ''
      this.showLogsAction = ''
      this.showLogsOwner = ''
      this.showLogsData = ''
    },

    getLogs() {
      axios.post(process.env.VUE_APP_URL_TALLOS_ADMIN_API + 'logs/find-logs', {limit: this.limit, page: this.page})
          .then((res) => {
            console.log(res.data)
            this.logs = res.data.docs
            this.totalLogs = res.data.total;
            this.totalPages = res.data.pages;
            this.pages = res.data.pages;
          });
    }
  }
}
</script>

<style>
.content-wrapper {
  background-color: #fff;
}

.btn {
  padding: 1.5px 4px;
}

.back-icon:hover {
  color: #6c757d;
}

@media (max-width: 576px) {
  .btn-danger {
    margin-top: 5%;
    margin-left: 0px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .btn-danger {
    margin-top: 5%;
    margin-left: 0px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .btn-danger {
    margin-top: 5%;
    margin-left: 0px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .btn-danger {
    margin-top: 5%;
    margin-left: 0px;
  }
}

@media (min-width: 993px) and (max-width: 1540px) {
  .btn-danger {
    margin-top: 5%;
    margin-left: 0px;
  }
}

@media (min-width: 1540px) {
  .btn-danger {
    margin-left: 5%;
  }
}
</style>